import * as React from 'react';
import { Link } from 'react-static';
import { FelaComponent, darken } from '@filou/core';

const rule = ({ theme, scale }: any) => ({
  '> svg': {
    width: 50,
    height: 50,
    transform: `scale(${scale})`,
    transition: 'all 0.3s ease-in-out 0s',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: '1.5',
    '& path': {
      fillOpacity: 0,
      stroke: theme.color,
      strokeWidth: 23
    },
    '& circle': {
      fillOpacity: 0,
      stroke: theme.color,
      strokeWidth: 23
    }
  },
  '> span': {
    color: darken(theme.color, -10)
  },
  display: 'flex',
  fontSize: 50 * scale,
  transform: `scale(${scale})`,
  lineHeight: '40px',
  color: theme.color,
  userSelect: 'none',
  height: 50,
  transition: 'all 0.3s ease-in-out 0s',
  textDecoration: 'none',
  position: 'relative'
});
const Logo = ({ scale = 1 }) => (
  <FelaComponent
    scale={scale}
    rule={rule}
    render={({ className }) => (
      <Link to="/" id="logo" className={className}>
        {/*<svg viewBox="0 0 363 363" version="1.1">
          <g transform="matrix(1,0,0,1,-278,-554)">
            <g transform="matrix(3.00009,0,0,2.53846,-1504.07,1036.31)">
              <rect
                x="594.004"
                y={-190}
                width="120.996"
                height={143}
                style={{ fill: 'none' }}
              />
              <g transform="matrix(0.707588,0.836266,-0.600912,0.710191,99.5527,-557.306)">
                <g transform="matrix(0.287188,0,0,0.339415,474.812,-289.311)">
                  <path d="M560.809,307.738C549.244,303.299 536.961,301.022 524.572,301.022C468.765,301.022 423.457,346.331 423.457,402.138C423.457,457.945 468.765,503.253 524.572,503.253C580.417,503.253 625.688,457.982 625.688,402.138C625.688,457.982 580.417,503.253 524.572,503.253C468.765,503.253 423.457,457.945 423.457,402.138C423.457,346.331 468.765,301.022 524.572,301.022C536.961,301.022 549.244,303.299 560.809,307.738Z" />
                </g>
                <g transform="matrix(0.287188,0,0,0.339415,532.89,-289.311)">
                  <circle cx="524.572" cy="402.138" r="101.115" />
                </g>
                <g transform="matrix(0.287188,2.77556e-17,0,0.339415,532.89,-289.311)">
                  <circle cx="524.572" cy="402.138" r="101.115" />
                </g>
              </g>
            </g>
          </g>
        </svg>*/}
        <span>co</span>
        .operativ
      </Link>
    )}
  />
);

export default Logo;
