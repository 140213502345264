import * as React from 'react';
import { withRouteData, Link } from 'react-static';
import {
  Map,
  Grid,
  Banner,
  TitleImage,
  Tabs,
  Nav,
  renderText,
  About,
  Sitemap,
  cloudinary
} from 'filou-web';
import { Logo } from '../components';
import { FelaComponent } from '@filou/core/lib';

class HashScroll extends React.Component {
  componentDidMount() {
    if (location.hash) {
      const el = document.getElementById(location.hash.substr(1));
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
  render() {
    return null;
  }
}

const Slice = ({ spezialistList, partnerList, type, items, ...primary }) => {
  if (type === '3-spalter') {
    const {
      ausrichtung,
      hintergrund,
      id: key,
      titel1,
      untertitel1,
      spalten
    } = primary;
    return (
      <Grid
        id={key && key.toLowerCase()}
        titleColor={hintergrund === 'Normal'}
        background={
          hintergrund === 'Dunkel'
            ? 'dark5'
            : hintergrund === 'Farbe'
              ? 'banner'
              : undefined
        }
        size={spalten}
        verticalGutter={spalten ? 30 : 0}
        title={titel1}
        subtitle={untertitel1}
        alignTitle={
          ausrichtung === 'Alles mittig' || ausrichtung === 'Titel mittig'
            ? 'center'
            : undefined
        }
        alignText={
          ausrichtung === 'Alles mittig' || ausrichtung === 'Text mittig'
            ? 'center'
            : undefined
        }
      >
        {items.map(({ bild1, text, titel1, untertitel1 }, index) => (
          <Grid.Item
            key={index}
            title={titel1}
            subtitle={untertitel1}
            img={bild1 && cloudinary(bild1.url)}
          >
            {text}
          </Grid.Item>
        ))}
      </Grid>
    );
  } else if (type === 'tabs') {
    const { id: key, titel1, untertitel1 } = primary;
    return (
      <Tabs
        id={key && key.toLowerCase()}
        titleColor
        keyField="titel1"
        //background="dark5"
        title={titel1}
        subtitle={untertitel1}
        items={items}
      />
    );
  } else if (type === 'partner') {
    const { id: key, titel1, untertitel1 } = primary;
    return (
      <FelaComponent
        rule={({ theme }) => ({
          transition: theme.transition,
          opacity: 0.4,
          transform: 'scale(0.9)',
          onHover: {
            opacity: 0.8,
            transform: 'scale(1)'
          }
        })}
        render={({ className }) => (
          <Grid
            id={key && key.toLowerCase()}
            titleColor
            align="center"
            title={titel1}
            subtitle={untertitel1}
          >
            {partnerList.map(item => (
              <Grid.Item
                className={className}
                key={item.id}
                img={
                  item.logo &&
                  cloudinary(item.logo.url, 'w_400,h_400,c_pad,e_grayscale')
                }
              />
            ))}
          </Grid>
        )}
      />
    );
  } else if (type === 'spezialisten') {
    const { id: key, titel1, untertitel1 } = primary;
    return (
      <FelaComponent
        rule={({ theme }) => ({
          transition: theme.transition,
          transform: 'scale(1)',
          onHover: {
            transform: 'scale(1.025)',
            boxShadow:
              'rgba(0, 0, 0, 0.07) 0px 1px 4px, rgba(0, 0, 0, 0.01) 0px 1px 3px'
          }
        })}
        render={({ className }) => (
          <Grid
            id={key && key.toLowerCase()}
            titleColor
            size={5}
            alignText="center"
            verticalGutter={20}
            title={titel1}
            subtitle={untertitel1}
            elements={
              <Map
                apiKey="AIzaSyCFYuN1vuG282LFYZgPXpx7Pk4b815Sw-g"
                items={[]}
              />
            }
          >
            {spezialistList.map(item => (
              <Grid.Item
                key={item.id}
                className={className}
                background="white"
                size={1}
                title={item.name}
                subtitle={item.schwerpunkt}
                render={({ className, children }) => (
                  <Link to={`/${item.slug}`} className={className}>
                    {children}
                  </Link>
                )}
                img={
                  item.bild && cloudinary(item.bild.url, 'w_400,h_400,c_fill')
                }
              />
            ))}
          </Grid>
        )}
      />
    );
  }
  return null;
};

const IndexRoute = ({
  spezialistList = [],
  partnerList = [],
  template = {}
}) => (
  <>
    <HashScroll />
    <Nav
      logo={Logo}
      nav={(template.body || []).map(x => x.key).filter(x => x)}
    />
    {template.bild && <TitleImage src={cloudinary(template.bild.url)} />}
    <Banner
      src={cloudinary('/banner.jpg')}
      title={template.titel}
      description={template.untertitel}
    />
    {(template.body || []).map((slice, index) => (
      <Slice
        {...slice}
        id={slice.key}
        key={index}
        spezialistList={spezialistList}
        partnerList={partnerList}
      />
    ))}
    <Grid background="dark">
      <Grid.Item title="co.operativ">
        <About>{renderText(template.foot)}</About>
      </Grid.Item>
      <Grid.Item title="Sitemap">
        <Sitemap>
          <Sitemap.Item to="/" label="Startseite">
            {(template.body || [])
              .map(x => x.key)
              .filter(x => x)
              .map(item => (
                <Sitemap.Item
                  key={item}
                  to={`/#${item.toLowerCase()}`}
                  label={item}
                />
              ))}
          </Sitemap.Item>
          <Sitemap.Item to="/" label="Impressum" />
          <Sitemap.Item label="Spezialisten">
            {spezialistList.map((item: any) => (
              <Sitemap.Item
                key={item.id}
                label={<Link to={`/${item.slug}`}>{item.name}</Link>}
              />
            ))}
          </Sitemap.Item>
        </Sitemap>
      </Grid.Item>
      <Grid.Item title="Impressum">{renderText(template.impressum)}</Grid.Item>
    </Grid>
  </>
);

export default withRouteData(IndexRoute);
